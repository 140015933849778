import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { common } from "constants";

import { actionGetPrevOrderInfo, actionSavePrevAddrSelected } from "redux/actions/customer";
import { actionClickSaveStoreInfo } from "redux/actions/store";
import { actionSaveOrderType } from "redux/actions/order";
import { getAvailableStoreToDelivery, getAvailableStoreToTakeaway } from "../services/store";

import { showAppLoading, showAppError, getError } from "../utils";

export default function WithSavedInfo(Component) {
  return (props) => {
    const dispatch = useDispatch();
    const prevOrderInfo = useSelector(({ customerReducer }) => customerReducer.prevOrderInfo);

    const defaultOrderType = useSelector(({ orderReducer }) => orderReducer.orderType);

    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);

    const handleSelectSavedInfo = async (e, data, disposition) => {
      setOpenScheduleDialog(false);
      showAppLoading();
      try {
        const orderType = disposition || defaultOrderType;
        dispatch(actionSaveOrderType(orderType));

        let store;
        if (orderType == common.ORDER_TYPE.DELIVERY) {
          const info = e ? prevOrderInfo.delivery_addresses.find((item) => item.uuid == e.target.value) : data;
          dispatch(actionSavePrevAddrSelected(info));
          store = await getAvailableStoreToDelivery(info.address_mapping_uuid);
        } else {
          const storeUUID = e ? e.target.value : data;
          store = await getAvailableStoreToTakeaway(storeUUID);
        }
        if (!store) return showAppLoading(false);

        dispatch(actionClickSaveStoreInfo(store));
      } catch (error) {
        showAppError(getError(error));
      } finally {
        showAppLoading(false);
      }
      setOpenScheduleDialog(true);
    };

    useEffect(async () => {
      dispatch(actionGetPrevOrderInfo());
    }, []);

    return (
      <Component
        {...props}
        prevOrderInfo={prevOrderInfo}
        onSelectSavedInfo={handleSelectSavedInfo}
        openScheduleDialog={openScheduleDialog}
      />
    );
  };
}
