import {
  SAVE_ORDER_TYPE,
  CLICK_ADD_TO_CART,
  SAVE_CART_INFO,
  SAVE_ORDER_SCHEDULE,
  GET_CART,
  CLICK_SAVE_ORDER_SCHEDULE,
  CLICK_DELETE_CART,
  CLEAR_CART,
  UPDATE_CHECKOUT_INFO,
  UPDATE_ORDER_SCHEDULE,
} from "./actionTypes";

export const actionSaveOrderType = (payload) => ({
  type: SAVE_ORDER_TYPE,
  payload,
});
export const actionSaveOrderSchedule = (payload) => ({
  type: SAVE_ORDER_SCHEDULE,
  payload,
});
export const actionClickSaveOrderSchedule = (payload) => ({
  type: CLICK_SAVE_ORDER_SCHEDULE,
  payload,
});
export const actionUpdateOrderSchedule = (payload) => ({
  type: UPDATE_ORDER_SCHEDULE,
  payload,
});
export const actionClickAddToCart = (payload) => ({
  type: CLICK_ADD_TO_CART,
  payload,
});

export const actionSaveCartInfo = (payload) => ({
  type: SAVE_CART_INFO,
  payload,
});

export const actionGetCart = () => ({
  type: GET_CART,
});

export const actionClearCart = () => ({
  type: CLEAR_CART,
});

export const actionClickDeleteCart = () => ({
  type: CLICK_DELETE_CART,
});

export const actionUpdateCheckoutInfo = (payload) => ({
  type: UPDATE_CHECKOUT_INFO,
  payload,
});
