import { fetchThirdParty } from '../fetch';
import { googleAPIKey } from '../../constants';

export const searchLocationByAddress = (address)=> {
    const params = {
        address,
        key: googleAPIKey
    }
    return fetchThirdParty({
        method: 'get',
        url: 'https://maps.googleapis.com/maps/api/geocode/json',
    }, params);
}

export const searchLocationByLatLong = (lat, long)=> {
    const params = {
        latlng: `${lat},${long}`,
        key: googleAPIKey
    }
    return fetchThirdParty({
        method: 'get',
        url: 'https://maps.googleapis.com/maps/api/geocode/json',
    }, params);
}