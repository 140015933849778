import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useTranslation } from "react-i18next";

const NotificationDialog = (props) => {
  const {
    title,
    description,
    open,
    onClose,
    onDone,
    justAlert = false,
    txtClose,
    txtDone,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      {title ? <DialogTitle>{title}</DialogTitle> : ""}
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {justAlert ? (
          <Button onClick={onDone} autoFocus>
            {t("common_close")}
          </Button>
        ) : (
          <>
            <Button onClick={onClose}>{t(txtClose)}</Button>
            <Button onClick={onDone} autoFocus>
              {t(txtDone)}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

NotificationDialog.defaultProps = {
  title: "",
  description: "",
  open: false,
  onClose: () => {},
  onDone: () => {},
  txtClose: "common_cancel",
  txtDone: "common_yes",
};
export default NotificationDialog;
