import React, { useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

import BottomButton from "components/Utils/BottomButton";
import NotificationDialog from "components/Utils/NotificationDialog";

import { common } from "constants";

import { actionClickSaveOrderSchedule } from "redux/actions/order";

import styled from "styled-components";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import { DialogContent } from "@mui/material";

import { filterTimeSlot, filterDateSlot } from "utils/Datetime";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const StyledText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #344054;
`;

const StyledLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #98a2b3;
  margin-top: 24px;
`;

const SchedulePopup = (props) => {
  const { t } = useTranslation();
  const { open, showNotify, handleClose, onDone } = props;
  const dispatch = useDispatch();
  const [openNotify, setOpenNotify] = useState(true);

  const orderType = useSelector(({ orderReducer }) => orderReducer.orderType);
  const orderSchedule = useSelector(
    ({ orderReducer }) => orderReducer.orderSchedule,
  );
  const store = useSelector(({ storeReducer }) => storeReducer.store);

  const businessHours = useSelector(
    ({ storeReducer }) => storeReducer.businessHours,
  );

  const isDelivery = orderType == common.ORDER_TYPE.DELIVERY;
  const dialogTitle = isDelivery ? "set_delivery_time" : "set_takeaway_time";

  const [dateSlot, setDateSlot] = useState(businessHours);
  const [timeSlot, setTimeSlot] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState(orderSchedule);

  const handleChangeDate = (e, date) => {
    const businessHour = businessHours.find(
      (item) => item.date == (e ? e.target.value : date),
    );

    if (!businessHour) return;
    let timeSlot = businessHour.time_slots;
    if (businessHour.today && !store.opened) {
      timeSlot = businessHour.time_slots.slice(1);
    }
    if (businessHour.today) {
      timeSlot = filterTimeSlot(timeSlot, businessHour.waiting_time);
      if (timeSlot.length == 1) {
        return;
      }
      setTimeSlot(timeSlot);
    } else {
      setTimeSlot(timeSlot);
    }
    setScheduleInfo({
      ...scheduleInfo,
      ...businessHour,
      ...(timeSlot[0] || {}),
    });
  };

  const loadTimeSlotByDate = (date) => {
    const businessHour = businessHours.find(
        (item) => item.date == date
    );

    if (!businessHour) return;
    let timeSlot = businessHour.time_slots;
    if (businessHour.today && !store.opened) {
      timeSlot = businessHour.time_slots.slice(1);
    }

    timeSlot = filterTimeSlot(timeSlot, businessHour.waiting_time);
    setTimeSlot(timeSlot);
    setScheduleInfo({
      ...scheduleInfo,
      ...(timeSlot[0] || {}),
    });
  }

  const handleChangeTime = (e) => {
    const time = timeSlot.find((item) => item.time == e.target.value);
    setScheduleInfo({ ...scheduleInfo, ...time });
  };

  const handleSaveScheduleInfo = () => {
    dispatch(actionClickSaveOrderSchedule(scheduleInfo));
    onDonePopup(scheduleInfo);
  };

  useEffect(() => {
    const newDateSlot = filterDateSlot(businessHours);
    setDateSlot(newDateSlot);
    loadTimeSlotByDate(orderSchedule.date);
  }, []);

  useEffect(() => {
    setScheduleInfo(orderSchedule);
  }, [orderSchedule]);

  const onClosePopup = () => {
    handleClose();
    setOpenNotify(true);
  };

  const onDonePopup = (scheduleInfo) => {
    onDone(scheduleInfo);
    setOpenNotify(true);
  };

  return (
    <>
      <NotificationDialog
        open={showNotify ? open && openNotify : false}
        description={t("change_time_notify")}
        onClose={onClosePopup}
        onDone={() => {
          setOpenNotify(false);
        }}
      />
      <Dialog
        fullScreen
        open={!showNotify ? open : open && !openNotify}
        onClose={onClosePopup}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClosePopup}>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t(dialogTitle)}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <StyledText>{t("change_time_sub_title")}</StyledText>
          <StyledLabel>{t("change_time_date")}</StyledLabel>
          <Select
            value={scheduleInfo.date}
            fullWidth={true}
            size="small"
            onChange={handleChangeDate}
          >
            {dateSlot.map((item) => (
              <MenuItem key={item.date} value={item.date}>
                {item.date_label} ({t(item.day)})
              </MenuItem>
            ))}
          </Select>
          <StyledLabel>{isDelivery ? t("change_delivery_time"): t("change_takeaway_time")}</StyledLabel>
          <Select
            value={scheduleInfo.time}
            fullWidth={true}
            size="small"
            onChange={handleChangeTime}
          >
            {timeSlot.map((item) => (
              <MenuItem key={item.time} value={item.time}>
                {item.time == 'ASAP' ? t(item.time_label) : item.time_label}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <BottomButton
          text={t("change_time_button_text")}
          onClick={handleSaveScheduleInfo}
        />
      </Dialog>
    </>
  );
};

SchedulePopup.defaultProps = {
  handleClose: () => {},
  oneDone: () => {},
};
export default SchedulePopup;
