import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import styled from "styled-components";
const StyledContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 1px -10px 20px -11px rgba(0, 0, 0, 0.59);
  -webkit-box-shadow: 1px -10px 20px -11px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 1px -10px 20px -11px rgba(0, 0, 0, 0.59);
`;

export default function BottomButton({ text, textLeft, textRight, onClick, disabled }) {
  return (
    <StyledContainer className="bottom-button">
      <Container>
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          onClick={!disabled ? onClick : () => { }}
          disabled={disabled}
        >
          {textLeft && textRight ?
            <Grid container spacing={2} column={12}>
              <Grid item xs={6} textAlign="left">
                {textLeft}
              </Grid>
              <Grid item xs={6} textAlign="right">
                {textRight}
              </Grid>
            </Grid> :
            text}
        </Button>
      </Container>
    </StyledContainer>
  );
}
