import {
  CUSTOMER_SAVE_USER,
  GET_PREV_ORDER_INFO,
  SAVE_PREV_ADDR_SELECTED,
} from "./actionTypes";

export const actionSaveCustomer = (payload) => ({
  type: CUSTOMER_SAVE_USER,
  payload,
});
export const actionGetPrevOrderInfo = () => ({ type: GET_PREV_ORDER_INFO });

export const actionSavePrevAddrSelected = (payload) => ({
  type: SAVE_PREV_ADDR_SELECTED,
  payload,
});
