import { storeAPI } from "../../constants";
import { fetchData } from "../fetch";
import { searchLocationByAddress } from "../google";
import { now } from "../../utils/Datetime";

export const findStoreByPostalCode = (postalCode, showErrMsg = true) => {
  const url = `${storeAPI}/get/line/stores/delivery`;
  const params = {
    postal_code: postalCode,
    datetime: now(),
  };
  return fetchData(
    {
      method: "get",
      url,
      showErrMsg,
    },
    params,
  );
};

export const findStoreByLatLong = (lat, long, showErrMsg = true) => {
  const url = `${storeAPI}/get/line/stores/collection`;
  const params = {
    lat,
    long,
    datetime: now(),
  };
  return fetchData(
    {
      method: "get",
      url,
      showErrMsg,
    },
    params,
  );
};

export const findStoreByAddress = async (address) => {
  const [addressInfo] = await searchLocationByAddress(address);
  if (!addressInfo) {
    return [];
  }
  const { lat, lng } = addressInfo.geometry.location;
  return findStoreByLatLong(lat, lng);
};

export const getAvailableStoreToDelivery = (addressMappingUUID) => {
  const url = `${storeAPI}/get/line/store/available?disposition=1&uuid=${addressMappingUUID}`;
  return fetchData({
    method: "get",
    url,
  });
};

export const getAvailableStoreToTakeaway = (storeUUID) => {
  const url = `${storeAPI}/get/line/store/available?disposition=2&uuid=${storeUUID}`;
  return fetchData({
    method: "get",
    url,
  });
};

export const getStoreDetail = (storeUUID, disposition) => {
  const url = `${storeAPI}/get/line/store/${storeUUID}`;
  const data = { disposition }
  return fetchData({
    method: "get",
    url,
  }, data);
};
