import {
  SAVE_ADDRESS_INFO,
  SAVE_POSTAL_CODE,
  SAVE_ADDRESS,
  CLICK_SAVE_STORE_INFO,
  CLEAR_STORE,
} from "./actionTypes";

export const actionSavePostalCode = (payload) => ({
  type: SAVE_POSTAL_CODE,
  payload,
});
export const actionSaveAddress = (payload) => ({ type: SAVE_ADDRESS, payload });

export const actionClickSaveStoreInfo = (payload) => ({
  type: CLICK_SAVE_STORE_INFO,
  payload,
});

export const actionSaveAddressInfo = (payload) => ({
  type: SAVE_ADDRESS_INFO,
  payload,
});

export const actionClearStore = () => ({
  type: CLEAR_STORE,
});
